// This mixin is used to control the right drawer width.
// We cannot use a % of the screen size for a drawer when we switch between
// a mini-variant and normal size.
// Related to this issue: https://github.com/vuetifyjs/vuetify/issues/8223

export default {
  data: () => ({
    screenWidth: 0,
  }),
  computed: {
    drawerWidth() {
      return Math.round(this.screenWidth * this.drawerWidthRatio)
    },
  },
  methods: {
    // whenever the document is resized, re-set the 'clientWidth' variable
    handleResize () {
      this.screenWidth = window.innerWidth
    },
  },
  watch: {

  },
  mounted() {
    window.addEventListener("resize", this.handleResize)
    this.handleResize()
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize)
  },
}
